@import './_variables';

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0.9em;
  background-color: var(--timer-background-color);
  color: var(--text);
  padding: 0 2.5em 2em 2.5em;
  border-radius: 50px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), -2px 17px 33.4px rgba(0, 0, 0, 0.086), 1px 7px 20px 2px rgba(0, 0, 0, 0.12);

  &__label {
    z-index: 3;
    color: $white;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
    line-height: 1;
    position: relative; }

  &__time {
    margin: 0;
    margin-bottom: 0;
    font-size: 50px;
    line-height: 1;
    font-weight: 400;
    position: relative;
    z-index: 1;
    color: $white;

    &::after {
      content: '';
      background: linear-gradient(90deg, #EDE574 0%, #E1F5C4 100%);
      width: 180px;
      height: 180px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1; } }

  &__controllers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em; }

  &__label2 {
    display: flex;
    align-items: center;
    width: 100%; }

  &__maintitle {
    margin-top: 0.5em;
    margin-bottom: 0.7em; } }

.nav-container div.nav-button:hover {
  background-color: rgb(42, 110, 81) !important; }

div.select-task:hover {
  background-color: rgb(42, 110, 81) !important; }

.nav-button {
  padding: 0.5em 0.7em;
  background-color: #4aa37d;
  border-radius: 10px;
  cursor: pointer; }

.select-task {
  padding: 0.5em 1em;
  background-color: #1d976c;
  margin-bottom: 1em;
  color: white;
  border-radius: 18px;
  cursor: pointer; }
.select-task-disabled {
  padding: 0.5em 1em;
  background-color: gray;
  margin-bottom: 1em;
  color: white;
  border-radius: 18px;
  cursor: not-allowed; }
.selected-task {
  padding: 0.5em;
  color: #13ad6d;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  text-align: center; }
.updateTask {
  padding: 0.5em;
  color: #13ad6d;
  font-weight: 600;
  text-align: center;
  margin: 0 0 15px 0; }
.treeAva {
  padding: 0.5em; }
.proButton {
  text-decoration: underline;
  cursor: pointer; }
[data-theme='dark'] {
  .timer {
    &__time {
      &::after {
        box-shadow: -1px 1px 24px 2px var(--timercircle-dark-box-shadow-color); } } }
  .nav-container div.nav-button:hover {
    background-color: #222 !important; }
  .nav-button {
    background-color: #47474d; }
  div.select-task:hover {
    background-color: #222 !important; }
  .select-task {
    background-color: #47474d; }
  .selected-task {
    color: #47474d; } }

.header {
    padding: 0 1em; }

@media screen and (max-width: 450px) {
  .timer {
    width: auto;
    margin: 0 0.9em;
    padding: 0.5em 1.5em; } }
@media screen and (max-width: 321px) {
  .timer__controllers {
    gap: 0; } }

