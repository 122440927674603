$main-font: 'Quicksand', sans-serif;

$white: #ffffff;
$black: #000000;
$bg-color: #282c34;
$box-shadow-color: #48f1330c;
$dark-box-shadow-color: #7b757517;
$timercircle-dark-box-shadow-color: #ffffff33;
$gradient-stop-1: #1D976C;
$gradient-stop-2: #93F9B9;
$dark-gradient-stop-1: #232526;
$dark-gradient-stop-2: #414345;
