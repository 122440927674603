.info-container {
    display: flex;
    justify-content: space-between;
    padding: 0 1em 1.2em 1em;
    gap: 0.5em; }
.info-co2,.info-temperature {
    display: flex;
    flex: 1;
    flex-direction: column;
    text-align: center;
    padding: 0.5em 1em;
    border-radius: 1em;
    cursor: pointer;
    background-color: rgba(0,0,0,0.20); }

.info-container div.info-temperature:hover {
    background-color: rgb(42, 110, 81) !important; }

[data-theme='dark'] {
  .info-container div.info-temperature:hover {
    background-color: #222 !important; }
  .info-temperature {
    background-color: #47474d; } }

