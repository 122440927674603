@import '../../_variables';

.theme-toggler {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    border: 4px solid white;
    border-radius: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .theme-toggler__slider {
            background-color: var(--background-gradient-stop-2); }

        &:checked + .theme-toggler__slider:before {
            transform: translateX(17px); }

        &:focus + .theme-toggler__slider {
            box-shadow: 0 0 1px var(--background-gradient-stop-2); } }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-gradient-stop-2);
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 15px;
            width: 15px;
            left: 4px;
            bottom: 4px;
            background-color: $white;
            transition: 0.4s; }

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%; } } } }
