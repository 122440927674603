@import '../../_variables';

.controls {
    &__wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -3em;
        z-index: 3;

        button {
            padding: 1.5em 1.6em;

            svg {
                font-size: 1.2em; } } } }
