@import '../../_variables';

.session {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center; }

    &__length {
        font-size: 26px;
        line-height: 1;
        font-weight: 400;
        padding: 0 0.4em;
        min-width: 27px; }

    &__label {
        font-weight: 600; } }
