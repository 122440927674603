.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0,0,0,0.75);
    display: flex;
    color: #222; }

.modal {
    margin: auto;
    width: 90%;
    max-width: 430px;
    background-color: white;
    border-radius: 1.5rem; }

.modal-content {
    padding: 0.5rem 1.5rem 1.5rem 1.5rem; }

.modal-header {
    padding: 1rem 1.5rem 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center; }

.modal-close {
    cursor: pointer; }

